<template>
  <SVCarouselImageSlide :is-mock="isEditing">
    <template #image>
      <RokkaImage
        :config="usedImageStyle"
        v-bind="image?.image"
        :loading="loading"
        :preload="preload"
        :source-width="image?.image?.file?.rokka?.sourceWidth"
        :source-height="image?.image?.file?.rokka?.sourceHeight"
        :hash="image?.image?.file?.rokka?.hash || ''"
        :class="{
          'self-start editor_image': isEditing,
          carousel__slide__image: !isEditing,
        }"
      />
    </template>

    <template #content>
      <h2 v-blokkli-editable:field_title class="text-2xl md:text-4xl mb-4">
        {{ title }}
      </h2>
      <div
        v-blokkli-editable:field_text="{ type: 'frame' }"
        class="ck-content hyphens-auto"
        v-html="text"
      />
    </template>
  </SVCarouselImageSlide>
</template>

<script lang="ts" setup>
import type { ParagraphTextImageFragment } from '#graphql-operations'
import { RokkaImage } from '#components'

defineProps<{
  title?: string
  text?: string
  image: ParagraphTextImageFragment['image']
}>()

const liveModeImageStyleConfig = defineImageStyle({
  type: 'sizes',
  aspectRatio: 4 / 3,
  sizes: {
    xs: 440,
    sm: 768,
    md: 800,
  },
})

const editModeImageStyleConfig = defineImageStyle({
  type: 'sizes',
  aspectRatio: 4 / 3,
  sizes: {
    sm: 384,
    md: 400,
  },
})

const { isEditing, index, parentType } = defineBlokkli({
  bundle: 'text_image',
})

const usedImageStyle = computed(() => {
  return isEditing ? editModeImageStyleConfig : liveModeImageStyleConfig
})

const preload = ref(false)
// If the index is 0, the image is most probably above the fold
const loading = computed(() => {
  if (index.value === 0 && !parentType) {
    preload.value = true
    return 'eager'
  }
  return 'lazy'
})
</script>

<style lang="postcss" scoped>
.editor_image {
  max-height: 200px;
  max-width: 200px;
  padding-right: 20px;
}
</style>
