
    import { defineAsyncComponent } from '#imports'
    
import BlokkliComponent_from_library from '/builds/bazinga/sv-group/frontend/node_modules/blokkli-beta/dist/runtime/components/Blocks/FromLibrary/index.vue'
import BlokkliComponent_accordeon from '/builds/bazinga/sv-group/frontend/components/Paragraph/Accordeon/index.vue'
import BlokkliComponent_button from '/builds/bazinga/sv-group/frontend/components/Paragraph/Button/index.vue'
import BlokkliComponent_call_to_action_section from '/builds/bazinga/sv-group/frontend/components/Paragraph/CallToActionSection/index.vue'
import BlokkliComponent_carousel from '/builds/bazinga/sv-group/frontend/components/Paragraph/Carousel/index.vue'
import BlokkliComponent_contact_box from '/builds/bazinga/sv-group/frontend/components/Paragraph/ContactBox/index.vue'
import BlokkliComponent_icon_text_item from '/builds/bazinga/sv-group/frontend/components/Paragraph/IconTextItem/index.vue'
import BlokkliComponent_icon_text_list from '/builds/bazinga/sv-group/frontend/components/Paragraph/IconTextList/index.vue'
import BlokkliComponent_iframe from '/builds/bazinga/sv-group/frontend/components/Paragraph/Iframe/index.vue'
import BlokkliComponent_image from '/builds/bazinga/sv-group/frontend/components/Paragraph/Image/index.vue'
import BlokkliComponent_media_list from '/builds/bazinga/sv-group/frontend/components/Paragraph/MediaList/index.vue'
import BlokkliComponent_quote from '/builds/bazinga/sv-group/frontend/components/Paragraph/Quote/index.vue'
import BlokkliComponent_teaser from '/builds/bazinga/sv-group/frontend/components/Paragraph/Teaser/index.vue'
import BlokkliComponent_teaser_list from '/builds/bazinga/sv-group/frontend/components/Paragraph/TeaserList/index.vue'
import BlokkliComponent_teaser_with_external_link from '/builds/bazinga/sv-group/frontend/components/Paragraph/TeaserWithExternalLink/index.vue'
import BlokkliComponent_text from '/builds/bazinga/sv-group/frontend/components/Paragraph/Text/index.vue'
import BlokkliComponent_text_image from '/builds/bazinga/sv-group/frontend/components/Paragraph/TextImage/index.vue'
import BlokkliComponent_video from '/builds/bazinga/sv-group/frontend/components/Paragraph/Video/index.vue'
import BlokkliComponent_blokkli_fragment from '/builds/bazinga/sv-group/frontend/node_modules/blokkli-beta/dist/runtime/components/Blocks/Fragment/index.vue'

const global: Record<string, any> = {
  block_from_library: BlokkliComponent_from_library,
  block_accordeon: BlokkliComponent_accordeon,
  block_button: BlokkliComponent_button,
  block_call_to_action_section: BlokkliComponent_call_to_action_section,
  block_carousel: BlokkliComponent_carousel,
  block_contact_box: BlokkliComponent_contact_box,
  block_icon_text_item: BlokkliComponent_icon_text_item,
  block_icon_text_list: BlokkliComponent_icon_text_list,
  block_iframe: BlokkliComponent_iframe,
  block_image: BlokkliComponent_image,
  block_media_list: BlokkliComponent_media_list,
  block_quote: BlokkliComponent_quote,
  block_teaser: BlokkliComponent_teaser,
  block_teaser_list: BlokkliComponent_teaser_list,
  block_teaser_with_external_link: BlokkliComponent_teaser_with_external_link,
  block_text: BlokkliComponent_text,
  block_text_image: BlokkliComponent_text_image,
  block_video: BlokkliComponent_video,
  block_blokkli_fragment: BlokkliComponent_blokkli_fragment
}

    
import BlokkliFragmentComponent_Test from '/builds/bazinga/sv-group/frontend/components/Paragraph/BlokkliFragment/FooBar/index.vue'

const globalFragments: Record<string, any> = {
  fragment_Test: BlokkliFragmentComponent_Test
}


const chunks: Record<string, () => Promise<any>> = {
  rare: () => import('#blokkli/chunk-rare')
}

const chunkMapping: Record<string, string> = {
  "block_view": "rare",
  "block_webform": "rare"
}

const fragmentChunkMapping: Record<string, string> = {}

export function getBlokkliItemComponent(bundle: string): any {
  const key = 'block_' + bundle
  if (global[key]) {
    return global[key]
  }
  const chunkName = chunkMapping[key]
  if (chunkName) {
    return defineAsyncComponent(() => chunks[chunkName]().then(chunk => {
      return chunk.default[key]
    }))
  }
}

export function getBlokkliFragmentComponent(name: string): any {
  const key = 'fragment_' + name
  if (globalFragments[key]) {
    return globalFragments[key]
  }
  const chunkName = fragmentChunkMapping[key]
  if (chunkName) {
    return defineAsyncComponent(() => chunks[chunkName]().then(chunk => {
      return chunk.default[key]
    }))
  }
}
