<template>
  <div class="grid-container my-12">
    <div
      class="grid-container-12 border-1 border-gray-300 transition-[border-color] ease-in-out duration-250 sm:py-14 md:py-28 px-4 sm:px-0"
      :class="paragraphClassList"
    >
      <div class="sm:grid grid-cols-12 my-12 md:my-0">
        <div class="content-center row-start-1 col-start-8 col-span-4">
          <RokkaImage
            v-if="showPhoto"
            v-bind="contact?.media"
            loading="lazy"
            :hash="contact?.media?.image?.file?.rokka?.hash ?? ''"
            :config="imageStyle"
            :source-width="contact?.media?.image?.file?.rokka?.sourceWidth"
            :source-height="contact?.media?.image?.file?.rokka?.sourceHeight"
            class="overflow-hidden w-full mb-2"
          />
        </div>
        <div class="content-center row-start-1 col-span-5 col-start-2">
          <h3 class="text-3xl lg:text-4xl mt-6 md:mt-0">
            {{ title }}
          </h3>
          <div v-if="text" class="leading-5 my-10 me-12" v-html="text"></div>
          <span class="font-bold whitespace-nowrap mt-10"
            >{{ contact?.name }} </span
          ><br />
          <template v-if="contact?.jobTitle">
            <span>{{ contact?.jobTitle }}</span>
            <br />
          </template>
          {{ contact?.fieldBusinessArea?.name }} <br />
          <div v-if="contact?.phoneNumber">
            <a
              class="p-0 font-bold"
              :class="buttonClassList"
              :href="'tel:' + contact?.phoneNumber"
            >
              {{ contact?.phoneNumber }}
            </a>
          </div>
          <div class="mt-10 inline-block">
            <a
              class="button show-icon"
              :href="`mailto:${contact?.mail}`"
              :class="buttonClassList"
            >
              {{ $texts('contact_button', 'Kontakt') }}
              <SpriteSymbol
                name="paperplane"
                class="ms-3 w-6 h-6 md:w-5 md:h-5"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphContactBoxFragment } from '#graphql-operations'
import { RokkaImage } from '#components'

defineProps<{
  contact: ParagraphContactBoxFragment['contact']
  text?: ParagraphContactBoxFragment['text']
  title?: ParagraphContactBoxFragment['title']
  showPhoto?: ParagraphContactBoxFragment['showPhoto']
}>()

const imageStyle = defineImageStyle({
  type: 'sizes',
  aspectRatio: 1 / 1,
  sizes: {
    sm: 700,
    md: 368,
  },
})

const { options } = defineBlokkli({
  bundle: 'contact_box',
  globalOptions: ['spacing'],
  options: {
    backgroundColor: {
      type: 'radios',
      label: 'Hintergrundfarbe',
      default: 'light',
      displayAs: 'colors',
      options: {
        light: {
          label: 'Hell',
          hex: '#FFFFFF',
        },
        red: {
          label: 'Rot',
          hex: '#E9423F',
        },
      },
    },
  },
})

const paragraphClassList = computed(() => {
  const classList = []

  if (options.value.backgroundColor === 'red') {
    classList.push('bg-group-500', 'text-white', 'border-0')
  }

  if (options.value.spacing === 'small') {
    classList.push('my-6', 'lg:my-10')
  } else if (options.value.spacing === 'large') {
    classList.push('my-12', 'lg:my-20')
  }

  return classList
})

const buttonClassList = computed(() => {
  const classList = []

  if (options.value.backgroundColor === 'red') {
    classList.push('is-inverted')
  }

  return classList
})
</script>

<style lang="postcss" scoped>
.hovered {
  a.button {
    @apply bg-group-800 transition-none;
  }
}
</style>
