<template>
  <div
    class="paragraph-teaser col-span-4 sm:col-span-6 md:col-span-4 lg:col-span-4 border transition-[border-color] ease-in-out duration-250 flex flex-col"
    :class="!noLinkWrap && 'hover:border-primary-500'"
  >
    <TeaserDefault
      v-if="url && title"
      :to="url.uri?.path || ''"
      :title="title"
      :text="text"
      :media="image"
      :no-link-wrap="noLinkWrap"
      class="flex-grow"
    />
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphTeaserWithExternalLinkFragment } from '#graphql-operations'

defineProps<{
  title?: ParagraphTeaserWithExternalLinkFragment['title']
  url?: ParagraphTeaserWithExternalLinkFragment['url']
  image?: ParagraphTeaserWithExternalLinkFragment['image']
  text?: ParagraphTeaserWithExternalLinkFragment['text']
  noLinkWrap?: boolean
}>()

defineBlokkli({
  bundle: 'teaser_with_external_link',
  editor: {
    editTitle: (el) => el.querySelector('h3')?.innerText,
    mockProps: (text) => {
      return {
        overrideTitle: text || 'Teaser Titel',
        link: {
          uri: {
            path: '/',
          },
        },
      }
    },
  },
})
</script>
