<template>
  <div :class="paragraphClassList">
    <iframe
      ref="iframe"
      :src="src"
      :class="{ 'pointer-events-none': isEditing }"
      class="w-full min-h-300"
      @load="onLoad"
    />
  </div>
</template>

<script lang="ts" setup>
import { iframeResizer } from 'iframe-resizer'
import type { ParagraphIframeFragment } from '#graphql-operations'

const { isEditing, options } = defineBlokkli({
  bundle: 'iframe',
  globalOptions: ['spacing'],
})

const props = defineProps<{
  url?: ParagraphIframeFragment['url']
}>()

const paragraphClassList = computed(() => {
  const classList = []

  if (options.value.spacing === 'small') {
    classList.push('py-6', 'lg:py-10')
  } else if (options.value.spacing === 'large') {
    classList.push('py-12', 'lg:py-20')
  }

  return classList
})

const iframe = ref<HTMLIFrameElement | null>(null)

const onLoad = () => {
  if (iframe.value) {
    iframeResizer(
      {
        autoResize: true,
      },
      iframe.value,
    )
  }
}

const src = computed(() => props.url?.uri?.path || '')

onBeforeUnmount(() => {
  if (iframe.value) {
    const i = iframe.value as any
    if (i.iFrameResizer) {
      i.iFrameResizer.removeListeners()
    }
  }
})
</script>
