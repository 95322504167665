<template>
  <div v-if="isMock">
    <slot />
  </div>

  <Carousel
    v-else
    ref="carouselInstance"
    :items-to-show="1.3"
    :breakpoints="breakPoints"
    :wrap-around="true"
    :transition="750"
    @click.capture="onClick"
    @slide-start="onSlide"
    @init="onInit"
  >
    <slot />

    <template #addons>
      <div
        ref="navigation"
        class="carousel__navigation-container--outer"
        :style="`--carousel-nav-top-position: ${navigationTopPosition}px`"
      >
        <div class="carousel__navigation-container">
          <nav class="carousel__navigation-container--inner">
            <button
              class="carousel__prev"
              :aria-label="$texts('slides.prev', 'Vorherige')"
              @click="prev()"
            >
              <SpriteSymbol name="arrow-left" class="w-6 h-6" />
            </button>

            <button
              class="carousel__next"
              :aria-label="$texts('slides.next', 'Nächste')"
              @click="next()"
            >
              <SpriteSymbol name="arrow-right" class="w-6 h-6" />
            </button>
          </nav>
        </div>
      </div>
    </template>
  </Carousel>
</template>

<script lang="ts" setup>
import { Carousel } from 'vue3-carousel'

const navigation = ref<HTMLElement>()
const navigationTopPosition = ref(0)

defineProps<{
  // Used for Blökkli. We don't want editors to have to scroll through the slides in order to add child blocks or edit existing slides.
  isMock?: boolean
}>()

const breakPoints = ref({
  1280: {
    itemsToShow: 1.5,
  },
})

const carouselInstance = ref<typeof Carousel>()

function prev() {
  carouselInstance.value?.prev()
}

function next() {
  carouselInstance.value?.next()
}

function onClick(e: MouseEvent) {
  if (!e.target || !(e.target instanceof Element)) {
    return
  }

  const closestLi = e.target.closest('li')

  if (!closestLi) {
    return
  }

  if (closestLi.classList.contains('carousel__slide--prev')) {
    prev()
  }

  if (closestLi.classList.contains('carousel__slide--next')) {
    next()
  }
}

function positionNav(accordingToSlide: HTMLElement) {
  navigationTopPosition.value =
    accordingToSlide.querySelector('.carousel__slide__image')?.clientHeight || 0
}

function onSlide(e: {
  currentSlideIndex: number
  prevSlideIndex: number
  slidesCount: number
  slidingToIndex: number
}) {
  // We need to get it the vanilla way because vue3-carousel doesn't offer
  // a way to get an actual slide instance or element by index.
  positionNav(
    carouselInstance.value?.$el.querySelectorAll('.carousel__slide--visible')[
      e.slidingToIndex > e.currentSlideIndex ? 2 : 0
    ],
  )
}

function onInit() {
  nextTick(() => {
    // We need to get it the vanilla way because vue3-carousel doesn't offer
    // a way to get an actual slide instance or element by index.
    positionNav(
      carouselInstance.value?.$el.querySelector('.carousel__slide--active'),
    )
  })
}
</script>
