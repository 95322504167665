<template>
  <div
    :class="[
      options.spacing === 'small' ? 'py-6 lg:py-10' : '',
      options.spacing === 'large' ? 'py-12 lg:py-20' : '',
      gridContainerSize,
    ]"
  >
    <div
      v-blokkli-droppable:field_image="{
        entityType: 'media',
        entityBundles: ['image'],
        cardinality: 1,
      }"
      class="paragraph-image"
      :class="[parentType ? layoutClassesInner : layoutClasses]"
    >
      <MediaImage
        v-bind="image"
        :image-style="imageStyle"
        :class="options.format === 'full' ? 'w-full' : ''"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphImageFragment } from '#graphql-operations'

defineOptions({
  name: 'ParagraphImage',
})

defineProps<{
  image?: ParagraphImageFragment['image']
}>()

const { parentType, options } = defineBlokkli({
  bundle: 'image',
  globalOptions: ['spacing'],
  options: {
    format: {
      type: 'radios',
      label: 'Bildformat',
      default: 'full',
      displayAs: 'icons',
      options: {
        full: { label: 'extra large', icon: 'icon-blokkli-option-xlarge' },
        big: { label: 'large', icon: 'icon-blokkli-option-large' },
        text: { label: 'medium', icon: 'icon-blokkli-option-medium' },
        small: { label: 'small', icon: 'icon-blokkli-option-small' },
        original: { label: 'original', icon: 'icon-blokkli-option-original' },
      },
    },
    formatAccordeon: {
      type: 'radios',
      label: 'Bildformat',
      default: 'text',
      displayAs: 'icons',
      options: {
        text: { label: 'medium', icon: 'icon-blokkli-option-medium' },
        small: { label: 'small', icon: 'icon-blokkli-option-small' },
      },
    },
  },
  editor: {
    editTitle: (el) => {
      const img = el.querySelector('img')
      return img?.alt || img?.title
    },
    getDraggableElement: (el) => el.querySelector('figure'),
    determineVisibleOptions: (ctx) => {
      if (!ctx.parentType) {
        return ['format', 'spacing']
      }
      return ['formatAccordeon']
    },
  },
})

const bigGrid = defineImageStyle({
  type: 'sizes',
  aspectRatio: 16 / 9,
  sizes: {
    xs: 770,
    sm: 984,
    md: 1380,
    lg: 1380,
  },
})

const textGrid = defineImageStyle({
  type: 'sizes',
  aspectRatio: 16 / 9,
  sizes: {
    xs: 728,
    sm: 1000,
    md: 912,
    lg: 912,
  },
})

const textGridOriginal = defineImageStyle({
  type: 'sizes',
  sizes: {
    xs: 728,
    sm: 1000,
    md: 912,
    lg: 912,
  },
})

const smallGrid = defineImageStyle({
  type: 'sizes',
  aspectRatio: 16 / 9,
  sizes: {
    xs: 400,
    sm: 767,
  },
})

const fullWidth = defineImageStyle({
  type: 'sizes',
  aspectRatio: 16 / 9,
  sizes: {
    xs: 728,
    sm: 984,
    md: 1400,
    lg: 1920,
  },
})

/**
 * The responsive image style name to use.
 */
const imageStyle = computed(() => {
  if (options.value.format === 'big') {
    return bigGrid
  } else if (options.value.format === 'text') {
    return textGrid
  } else if (options.value.format === 'small') {
    return smallGrid
  } else if (options.value.format === 'original') {
    return textGridOriginal
  }
  return fullWidth
})

const layoutClasses = computed(() => {
  if (options.value.format === 'big') {
    return 'col-span-4 sm:col-span-6 md:col-span-8 lg:col-span-12'
  } else if (
    options.value.format === 'text' ||
    options.value.format === 'original'
  ) {
    return 'col-span-4 sm:col-span-6 md:col-start-2 md:col-span-6 lg:col-start-3 lg:col-span-8'
  } else if (options.value.format === 'small') {
    return 'col-span-4 md:col-start-2 md:col-span-5 lg:col-start-3 lg:col-span-6'
  }
  return 'w-full flex justify-center items-center'
})

const layoutClassesInner = computed(() => {
  if (options.value.formatAccordeon === 'small') {
    return 'col-span-4 md:col-span-5 lg:col-span-6'
  }
  return 'col-span-4 sm:col-span-6 lg:col-span-8'
})

const gridContainerSize = computed(() => {
  if (options.value.format !== 'full' && !parentType.value) {
    return 'grid-container'
  } else if (parentType.value) {
    return 'grid-container is-small mb-5'
  }
})
</script>
