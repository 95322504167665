<template>
  <div v-if="noLinkWrap" class="teaser group flex flex-col text-start">
    <div class="mb-5">
      <MediaImage
        v-if="media"
        v-bind="media"
        :image-style="imageStyle"
        :hide-caption="true"
      />
    </div>
    <h3 class="text-xl md:text-2xl px-5 mb-3">
      {{ title }}
    </h3>
    <div v-if="text" class="mb-5 px-5 flex-grow" v-html="text" />
    <VuepalLink
      :to="to"
      class="text-primary-600 font-bold px-5 mb-5 inline-flex gap"
    >
      {{ $texts('more_information', 'Mehr Informationen') }}

      <SpriteSymbol
        name="arrow-right"
        class="w-6 h-6 transition-all ease-in-out duration-250 max-w-0 [.teaser:hover_&]:max-w-6 -translate-x-0.5 [.teaser:hover_&]:translate-x-0 opacity-0 [.teaser:hover_&]:opacity-100"
      />
    </VuepalLink>
  </div>
  <VuepalLink v-else :to="to" class="teaser group flex flex-col text-start">
    <div class="mb-5">
      <MediaImage
        v-if="media"
        v-bind="media"
        :image-style="imageStyle"
        :hide-caption="true"
      />
    </div>
    <h3 class="text-xl md:text-2xl px-5 mb-3">
      {{ title }}
    </h3>
    <div v-if="text" class="mb-5 px-5 flex-grow" v-html="text" />
    <p class="text-primary-600 font-bold px-5 mb-5 mt-auto inline-flex gap">
      {{ $texts('more_information', 'Mehr Informationen') }}

      <SpriteSymbol
        name="arrow-right"
        class="w-6 h-6 transition-all ease-in-out duration-250 max-w-0 [.teaser:hover_&]:max-w-6 -translate-x-0.5 [.teaser:hover_&]:translate-x-0 opacity-0 [.teaser:hover_&]:opacity-100"
      />
    </p>
  </VuepalLink>
</template>

<script lang="ts" setup>
import type { MediaImageFragment } from '#graphql-operations'

defineProps<{
  title: string
  text?: string
  to: string
  media?: MediaImageFragment
  noLinkWrap?: boolean
}>()

const imageStyle = defineImageStyle({
  type: 'sizes',
  aspectRatio: 4 / 3,
  sizes: {
    xs: 728,
    sm: 1000,
    md: 912,
    lg: 912,
  },
})
</script>
