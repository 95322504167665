<template>
  <div class="container" :class="paragraphClassList">
    <h2
      v-blokkli-editable:field_title
      class="text-3xl md:text-4xl mb-10 lg:mb-14"
    >
      {{ title }}
    </h2>
    <div class="grid-container gap-10 mb-12 lg:mb-20">
      <div
        v-for="(item, i) in mediaList"
        :key="`item_${i}`"
        class="teaser col-span-4 sm:col-span-6 md:col-span-4 lg:col-span-4 border hover:border-primary-500 transition-[border-color] ease-in-out duration-250 flex flex-col"
      >
        <div class="mb-5 max-h-[200px] overflow-hidden">
          <MediaImage v-if="item" v-bind="item" :hide-caption="true" />
        </div>
        <h3
          v-if="item?.image?.alt || item?.name"
          class="text-xl md:text-2xl px-5 mb-12 hyphens-auto"
        >
          {{ item?.image?.alt || item?.name }}
        </h3>
        <button
          class="text-base text-primary-600 font-bold px-5 mb-5 inline-flex gap mt-auto"
          @click="downloadFile(item?.mediaFileUrl?.path)"
        >
          {{ $texts('download', 'Download') }}
          <SpriteSymbol
            name="download"
            class="w-6 h-6 ml-2 fill-primary-600 transition-all ease-in-out duration-250 max-h-0 [.teaser:hover_&]:max-h-6 -translate-y-0.5 [.teaser:hover_&]:translate-y-0 opacity-0 [.teaser:hover_&]:opacity-100"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphMediaListFragment } from '#graphql-operations'

defineProps<{
  title: ParagraphMediaListFragment['title']
  mediaList: ParagraphMediaListFragment['mediaList']
}>()

const { options } = defineBlokkli({
  bundle: 'media_list',
  globalOptions: ['spacing'],
  editor: {
    editTitle: (el) => el.querySelector('h2')?.textContent,
  },
})

const paragraphClassList = computed(() => {
  const classList = []

  if (options.value.spacing === 'small') {
    classList.push('py-6', 'lg:py-10')
  } else if (options.value.spacing === 'large') {
    classList.push('py-12', 'lg:py-20')
  }

  return classList
})

async function downloadFile(fileUrl: URL) {
  if (!fileUrl) {
    return
  }

  try {
    const response = await fetch(fileUrl)
    if (!response.ok) throw new Error('Failed to fetch the file')

    const blob = await response.blob()
    const blobUrl = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = blobUrl
    link.download = fileUrl.toString().split('/').pop() ?? 'download'
    document.body.appendChild(link)
    link.click()

    document.body.removeChild(link)
    URL.revokeObjectURL(blobUrl)
  } catch (error) {
    console.error('Error downloading the file:', error)
  }
}
</script>
